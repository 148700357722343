@import "../../../theme.scss";

.nft-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.nft-list {
  width: 100%;
  display: grid;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 30px;

  // Default to 1 column for small screens
  grid-template-columns: 1fr;

  // For medium screens (e.g., tablets)
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns
    padding: 10px 100px;
  }

  // For large screens (e.g., desktops)
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr); // 3 columns
    padding: 10px 100px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-container {
  text-align: center;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
}

.error-container h2 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.error-container p {
  font-size: 16px;
}

.no-nft-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px;
  gap: 20px;
  color: $purple-color;
  background-color: #fff;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

.no-nft-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 $yellow-color;
}

.no-nft-title {
  font-size: 18px;
  font-weight: bold;
}

.no-nft-icon {
  color: $yellow-color;
}
