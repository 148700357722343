@import "../../../theme.scss";

.nft-sidebar-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  flex: 1;
  background-color: $light-purple-color;
}

// smaller screens increase top padding
@media (max-width: 600px) {
  .nft-sidebar-container {
    padding-top: 40px;
  }
}

.nft-sidebar-icon {
  border: 1px solid $purple-color;
  color: $yellow-color;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color, transform 0.3s ease-in-out;

  .nft-sidebar-single-icon {
    color: $purple-color;
  }

  &:hover {
    transform: scale(1.05);
    background-color: $purple-color;
    .nft-sidebar-single-icon {
      color: $yellow-color;
    }
    span {
      color: $yellow-color;
    }
  }

  &.active {
    background-color: $purple-color;
    span {
      color: $yellow-color;
    }
    .nft-sidebar-single-icon {
      color: $yellow-color;
    }
  }

  span {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $purple-color;
  }
}

.nft-sidebar-single-icon-container {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
