.chatbot-button {
  position: fixed;
  bottom: 70px;
  right: 60px;
  border-radius: 30%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c2c76 !important;
  border: none;
  color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;

  &:hover {
    background-color: darken(#2c2c76, 10%);
  }
}

@keyframes chatDotsAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}

.chatbot-button .chat-dots-icon {
  animation: chatDotsAnimation 1s infinite;
}
.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 50vh; 
  background-color: #f4f4f7;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.chatbot-window {
  display: flex;
  flex-direction: column;
  height: 86vh;
  border: none;
  border-radius: 0; 
  background-color: #fff;
  overflow: hidden;
}

.chat-window {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  overflow: hidden; 
}

.messages {
  flex: 1;
  display: flex;
  flex-direction: column-reverse; 
  overflow-y: auto;
  margin-bottom: 10px;
  -ms-overflow-style: none;
}

.message {
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  max-width: 70%;
}

.message.user {
  background-color: #b0b4b8;
  color: white;
  align-self: flex-end; 
}

.message.assistant {
  background-color: #e9ecef;
  color: black;
  align-self: flex-start;
}

.input-area {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  justify-content: space-between;
}

.input-area input {
  flex: 1;
  padding: 10px;
}

.input-area button {
  padding: 10px 10px;
  display: flex;
  border: none;
  border-radius: 5px;
  background-color: #2c2c76;
  color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 50px;
  
&:hover {
  background-color: darken(#2c2c76, 10%);
  border-color: darken(#2c2c76, 10%);
}

}

.input-area button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.status {
  margin-top: 10px;
  font-size: 0.9em;
  color: #666;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #2c2c76;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.close-btn{
background-color: #f4f4f7;
text-align: center;
padding-bottom: 4px;
margin-right: 4px;
border: 2px solid #ccc;
border-radius: 5px;
&:hover {
  background-color: darken(#2c2c76, 10%);
  border-color: darken(#2c2c76, 10%);
  color: white;
}

}
.max-btn{
background-color: #f4f4f7;
text-align: center;
padding-bottom: 4px;
margin-right: 4px;
border: 2px solid #ccc;
border-radius: 5px;
&:hover {
  background-color: darken(#2c2c76, 10%);
  border-color: darken(#2c2c76, 10%);
  color: white;
}

}

.custom-textarea {
border: none !important;
resize: none; 
overflow: hidden; 
transition: border 0.2s ease-in-out;
}

.custom-textarea:hover,
.custom-textarea:focus {
border: none !important; 
outline: none !important;
box-shadow: none !important;
}

.custom-textarea::placeholder {
  font-size: 14px;
}

.chatbot-microphone-icon {
background-color: green !important ;
}

.chatbot-microphone-icon:hover {
background-color: #f6cd47 !important ;
}

.chatbot-microphone-icon-isListening {
background-color: #c42121 !important ;
}
.remove-left-border-radius {
border-top-left-radius: 0px !important;
border-bottom-left-radius: 0px !important;
}

.mic-send-btns {
display: flex;
align-items: flex-end;
margin-bottom: 3px;
margin-right: 3px;
margin-top: 3px;
}

.sound-btn {
background-color: #f4f4f7;
text-align: center;
padding: 4px 4px; 
border: 2px solid #ccc;
border-radius: 5px;
margin-right: 4px;
cursor: pointer;
font-size: 0.90rem; 

&:hover {
  background-color: darken(#2c2c76, 10%);
  border-color: darken(#2c2c76, 10%);
  color: white;
}
}