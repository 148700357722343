@import "../../index.scss";

.containerOverride {
  min-height: 400px !important;
}

.searchButton {
  border-radius: 0 !important;
}

.searchButton:focus {
  box-shadow: none !important;
}

.address {
  background-color: $light-purple-color;
  padding: 20px;
  border-radius: 15px;
  display: table-cell;
}