.text-title{
text-align: center;
padding: 45;
}
.signin-box {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 60vh;
  }
  .card1 {
    width: 1000px !important;
  }
  .button-with-image img {
    height: 70px;
    width: 70px;
  }
  .button-with-image {
    background: none !important;
    border: none !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px !important;
  }