@import "../../../theme.scss";

.nft-action-buttons {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  .buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    // for smaller screens flex-direction is column
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
}

.ownership-button,
.sell-offer-button,
.burn-button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  width: 48%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      transform: none;
    }
  }

  &:hover {
    transform: translateY(-2px);
  }

  &.ownership-button {
    border: 1px solid $yellow-color;
    background-color: $light-purple-color;
    color: $purple-color;
  }

  &.ownership-button:hover {
    background-color: $yellow-color;
  }

  &.sell-offer-button {
    background-color: $purple-color;
    color: $yellow-color;
  }

  &.sell-offer-button:hover {
    background-color: #1565c0;
  }

  &.burn-button {
    background-color: #d32f2f;
  }

  &.burn-button:hover {
    background-color: #c62828;
  }
}

.accept-button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  width: 100%;
  border: 1px solid $yellow-color;
  background-color: $light-purple-color;
  color: $purple-color;

  &:hover {
    background-color: $yellow-color;
  }
}

.sell-offer-form,
.transfer-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  gap: 8px;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sell-offer-form label,
.transfer-form label {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.95rem;
}

.sell-offer-form input,
.transfer-form input {
  padding: 8px 12px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;

  &:focus {
    border-color: #1976d2;
    outline: none;
  }
}

.submit-sell-offer-button,
.submit-transfer-button {
  padding: 10px 15px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #1565c0;
    transform: translateY(-2px);
  }
}

.error-message {
  color: #d32f2f;
  font-size: 0.875rem;
  font-weight: bold;
}

@media (max-width: 600px) {
  .nft-action-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .ownership-button,
  .sell-offer-button,
  .burn-button {
    width: 100%;
  }

  .sell-offer-form input,
  .transfer-form input {
    max-width: 100%;
  }
}
