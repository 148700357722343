.app-container {
    display: flex;
    height: calc(100vh - 100px);
    
    .main-content {
      flex: 1; 
      transition: flex 0.3s ease-in-out; 
      overflow-y: auto; 
      -ms-overflow-style: none;
    }
  
    .chatbot-container {
      flex: 0; 
      background-color: #ffffff;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
      transition: flex 0.3s ease-in-out; 
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 86vh;
      width: 0;
      overflow: hidden;
    }
  
    &.chatbot-open {
      .main-content {
        flex: 0.8; 
      }
  
      .chatbot-container {
        flex: 0.2; 
      }
    }
  }