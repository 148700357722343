@import "../../theme.scss";

.hotel-search-page {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.hotel-list {
  padding-left: 120px;
  padding-right: 120px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.show_more {
  border: 1px solid #a9a6a6;
  border-radius: 5px;
  background: rgb(44 44 118);
  background-color: rgb(44 44 118) !important;
  width: 15%;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
}

.show_more:hover {
  background-color: #0c0c79;
}

.show_more:active {
  background-color: #060642;
}

.centered-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: #060642 !important;
}

.nft-detail-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  text-align: center;

  .nft-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .nft-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: #1f2937;
    }

    .nft-sub-title {
      font-size: 1rem;
      font-weight: 400;
      color: #999;
    }
  }

  .marketplace-button {
    background-color: $purple-color;
    color: $yellow-color;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background-color: #1e40af;
    }

    &:active {
      background-color: #1d4ed8;
      transform: translateY(0);
    }
  }
}

.search-criteria-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 12px;
  margin-bottom: 15px;
  background-color: #fff;

  span {
    font-size: 1.1rem;
    color: $purple-color;
  }

  .change-search-btn {
    background-color: $purple-color;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 1rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}
