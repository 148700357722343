@import "../../index";

.main-image-div {
  background: url("../../../public/Assets/Images/dashboard/Sigiriya.webp");
  height: 575px;
  background-size: cover;
  background-position: center -150px;

  .main-txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 25%;
    width: 100%;
    height: 100vh;

    h3 {
      font-size: 3.7rem;
      text-align: center;
    }

    p {
      font-size: 2rem;
      text-align: center;
    }
  }
}

.search_section {
  margin-top: -80px;
  z-index: 2;

  .container {
    display: flex;
    padding-left: 4px;
  }

  .icon-and-text {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    padding-left: 2px;
    color: #f9b625;
    font-size: 20px;
  }

  .search-area-phrase {
    color: #000000;
    font-weight: 500;
    font-size: 15px;
  }

  .search-area {
    background-color: $light-purple-color;
    padding: 30px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    .row {
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      input:hover,
      input:active,
      input:focus {
        outline: none !important;
        box-shadow: none;
      }
    }

    .range-picker-input {
      display: inline-flex;
      width: 100%;
      border: none;

      .picker-input__text {
        padding-left: 10px;
        height: 39px;
        border-radius: 8px !important;
        border: 2px solid rgb(102, 102, 102) !important;
        width: 100% !important;
      }
    }

    .calendar__head {
      background: $purple-color;
    }
  }
  .listening {
    color: #c42121;
    font-weight: 600;
    font-size: x-large;
    padding-left: 1030px;
  }
}

div.best_offers {
  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
  text-align: left;

  .offer_items_flexbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;

    color: #000000;
  }
  .titleParagraph {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
  }
}

.overrideSearchButton {
  height: 40px;
  width: 100%;
  margin-bottom: 30px;
  background-color: $purple-color !important;
}

.search-wrapper-row1 {
  height: 40px;
}
.search-wrapper-row2 {
  padding-top: 60px;
}

.microphone-icon {
  width: 100px;
  height: 50px;
  background-color: green !important ;
  margin-bottom: 15px;
}

.microphone-icon:hover {
  background-color: #f6cd47 !important ;
  width: 100px;
  height: 50px;
  margin-bottom: 15px;
}

.microphone-icon-isListening {
  background-color: #c42121 !important ;
  width: 100px;
  height: 50px;
  margin-bottom: 15px;
}

.search-icon {
  width: 100px;
  height: 50px;
  background-color: #2c2c76 !important ;
  margin-bottom: 15px;
}

.remove-left-border-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.remove-right-border-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.top_rated_hotels {
  clear: inherit;
  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
  text-align: left;
  width: 100%;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;

    color: #000000;
  }

  .headline {
    padding-bottom: 30px;
  }
  .hotel_items_flexbox {
    display: flex;
    flex-wrap: wrap;
  }

  .hotel_card {
    margin: 20px;
    flex: 1 0 21%;
  }
}

.explore {
  height: 414px;
  background-color: #e6e6fa;
  .ellipse {
    margin-top: 152px;
  }
}

.exploreTitle {
  .title {
    padding-top: 94px;
    padding-left: 48px;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
  }
  .exploreBtn {
    margin-top: 92px;
    margin-left: 48px;
  }
}
.exploreCards {
  display: flex;
  justify-content: center; /* centers horizontally */
  align-items: center; /* centers vertically */
  .explorecd {
  }
}

div.planner {
  clear: inherit;
  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
  text-align: left;
  width: 100%;
}

div.searchDestination {
  .input {
    display: flex;
    margin-right: 0;
    width: 258px;
  }
}

div.quickPlanner {
}

.connect {
  height: 364px;
  background: url("../../Assets/Images/ariel-beach.webp");

  background-size: cover;
}

.wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  margin: 0;
}

.connectTitle {
  padding-left: 42px;
  padding-top: 40px;
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
  color: white;
}

.connectDesc {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: white;
  padding-left: 42px;
  padding-top: 23px;
}

.connectButton {
  padding-left: 42px;
  padding-top: 23px;
  .connectButtonInside {
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .dashboard-main-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
  }
}
