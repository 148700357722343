$main-yellow-color: rgb(251 183 37);
$main-blue-color: rgb(44 44 118);

.top-up-modal-overlay {
  width: 100%;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-up-modal {
  width: 300px;
  @media (min-width: 640px) {
    width: 400px;
  }
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    transition: all 0.5s;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }

  .modal-title {
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: black;
    padding: 10px;
    padding: 10px 0 10px;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    label {
      display: flex;
      flex-direction: column;
      font-size: 1rem;

      input {
        padding: 0.5rem;
        border: 1px solid #cccccc;
        border-radius: 0.25rem;
        margin-top: 0.5rem;
      }
    }
  }
}

.wallet-button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: $main-blue-color !important;
  color: #fff;
  font-size: 16px;
  text-align: center;
}

.wallet-button:hover {
  background-color: $main-blue-color !important;
}
