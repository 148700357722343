@import "../index.scss";
.star_button {
  background-color: transparent;
  //display: inline;
  cursor: pointer;
}
.star_button_on {
  color: #000;
}
.star_button_off {
  color: #ccc;
}

.navigation_button {
  background: #f9f7f7;
  padding: 5px 40px;
  border-radius: 3px;
  color: #000000;
  
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  margin: 5px 20px;

  border: 1px solid #a9a6a6;
  //box-sizing: border-box;
}

.navigation_button_active {
  background: #d9d9d9;
}

.upload_button {
  background: $purple-color;
  padding: 10px 40px;
  border-radius: 5px;
  color: #d9d9d9;
  
  font-weight: 500;
  font-size: 16px;
  align-items: center;
}

.upload_button:hover {
  background-color: $purple-color; /* Change the background color */
}

.upload_button:active {
  background-color: $purple-color; /* Change the background color */
}

.complete_registration_button {
  background: $purple-color;
  padding: 10px 40px;
  border-radius: 5px;
  color: #d9d9d9;
  
  font-weight: 500;
  font-size: 16px;
  align-items: center;
}

.complete_registration_button:hover {
  background-color: $purple-color; /* Change the background color */
}

.complete_registration_button:active {
  background-color: $purple-color; /* Change the background color */
}

.create_room_button {
  background: $purple-color;
  padding: 10px 20px;
  border-radius: 5px;
  color: #d9d9d9;
  
  font-weight: 500;
  font-size: 16px;
  align-items: flex-start;
}

.create_room_button:hover {
  background-color: $purple-color; /* Change the background color */
}

.create_room_button:active {
  background-color: $purple-color; /* Change the background color */
}

.create_room_button:disabled {
  background-color: #444469;
  cursor: not-allowed;
}

.delete_button {
  border: none;
  background-color: transparent;
  color: #fd1313;
}

.delete_button:hover {
  color: #bb0404;
}

.delete_button:active {
  color: #720202;
}

.rating_button {
  border-radius: 5px 0 0 5px;
  background-color: #ffffff;
  border: 1px solid #908f8f;
}
