.title_1 {
  
  font-weight: 700;
  font-size: 30px;
  line-height: 48px;
}

.title_2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 44px;
}

.title_3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
}

.title_3_sub {
  
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
}

.title_4 {
  
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
}

.subtext {
  
  font-weight: 500;
  font-size: 15px;
  color: #908f8f;
  margin-top: 0;
}

.image_name {
  
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.image_subtext {
  
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #908f8f;
  margin-top: 0;
}

.input_half {
  width: 530px !important;
  box-sizing: border-box;
  height: 50px;
  border-radius: 5px;
  background-color: #ffffff;
  border-color: #908f8f;
}

.input_full {
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  border-radius: 5px;
  //margin-top: 15px;
  background-color: #ffffff;
  border-color: #908f8f;
}

.dropdown_1 {
  height: 50px;
  border-radius: 5px;
  width: 50%;
  text-align: center;
  background-color: #ffffff;
}

.dropdown_items {
  height: 35px;
  width: 50%;
  text-align: center;
  background-color: #ffffff;
  
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}

.dropdown_room {
  height: 50px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
}

.dropdown_bed_container {
  //height: 50px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  display: flex;
  width: 100%;
}

.dropdown_bed_type {
  width: 100%;
  //height: 50px;
  //margin-top: 5px;
  
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  border: none;
  background-color: #ffffff;
}

.multiline {
  word-wrap: break-word;
  white-space: pre-line;
}

.bold-text {
  font-weight: 800;
}
