@import "../../../theme.scss";

.sell-offer-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.sell-offers-list {
  width: 100%;
  display: grid;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 30px;

  // Default to 1 column for small screens
  grid-template-columns: 1fr;

  // For medium screens (e.g., tablets)
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns
    padding: 10px 100px;
  }

  // For large screens (e.g., desktops)
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr); // 3 columns
    padding: 10px 100px;
  }
}

.no-fferrs-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  gap: 20px;
  color: $purple-color;
  background-color: #fff;
}

.no-offers-sub-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 $yellow-color;
}

.no-offers-title {
  font-size: 18px;
  font-weight: bold;
}

.no-offers-icon {
  color: $yellow-color;
}

.no-sell-offers-message {
  background-color: #e9ecef;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  max-width: 300px;
  margin-top: 20px;
}
