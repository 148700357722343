.dropdown {
  // border: 1px solid #908F8F;
  //height: 40px;
  //padding-left: 30px;
}

.city_text {
  width: 160px;
  text-align: left;
  padding-top: 5px;
  padding-left: 20px;
}

.date_range_text {
  width: 210px;
  text-align: left;
  padding-top: 5px;
  padding-left: 20px;
}

.people_text {
  width: 110px;
  text-align: left;
  padding-top: 5px;
  padding-left: 20px;
}

.bedroom_text {
  text-align: left;
  padding-top: 2px;
  padding-right: 10px;
  padding-left: 7px;
}

.increment_button {
  border: 1px solid #a9a6a6;
  border-radius: 5px;
  background: transparent;
  height: 25px;
  width: 25px;
  margin-top: 7px;
}

.increment_button:hover {
  background: #cecccc;
}

.increment_button:active {
  background: #afadad;
}

.decrement_button {
  border: 1px solid #a9a6a6;
  border-radius: 5px;
  background: transparent;
  height: 25px;
  width: 25px;
  margin-top: 7px;
  margin-right: 10px;
}

.decrement_button:hover {
  background: #cecccc;
}

.decrement_button:active {
  background: #afadad;
}

.clear_button {
  border: 1px solid #a9a6a6;
  border-radius: 5px;
  background: #2c2c76;
  color: #d9d9d9;
  width: 100%;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
}

.clear_button:hover {
  background-color: #0c0c79;
}

.clear_button:active {
  background: #afadad;
}

.search_button {
  border: 1px solid #2c2c76;
  border-radius: 5px;
  background: #2c2c76 !important;
  color: #ffffff;
  width: 100%;
  height: 38px;
  margin-top: 8px;
  margin-left: 2px;
}

.search_button:hover {
  background-color: #0c0c79;
}

.search_button:active {
  background-color: #060642;
}

.filter_card {
  box-sizing: border-box;
  border: 2px solid #f0efef;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 6px;
  padding: 10px 15px;
  background: #ffffff;
  width: 400px;
}

.reset_button {
  background: transparent;
  border: none;
  width: auto;
  margin-top: -5px;
  color: #555555;
}

.reset_button:hover {
  color: #1e1d1d;
}

.reset_button:active {
  color: #000000;
}

.reset_text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
}

.input_field {
  background-color: #ffffff;
  border-color: #908f8f;
  width: 50%;
  height: 38px;
  margin-top: 5px;
  font-family: "Inter", serif;
  font-weight: 500;
  line-height: 34px;
}

.box_button {
  border: 1px solid #a9a6a6;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 5px;
  height: 40px;
  width: auto;
  background-color: transparent;
  color: #000000;
}

.disabled_box_button {
  border: 1px solid #a9a6a6;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 5px;
  height: 40px;
  width: auto;
  background-color: transparent;
  color: #908f8f;
}

.box_text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
}

.box_button:hover {
  background: #cecccc;
}

.box_button:active {
  background: #afadad;
  color: #000000;
}

.box_button_active {
  background: #afadad;
  color: #000000;
}

.dropdown_container {
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 5px;
  display: flex;
  width: 75%;
}

.dropdown_container_disabled {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 5px;
  display: flex;
  width: 75%;
}

.dropdown_toggle {
  width: 100%;
  height: 38px;
  //margin-top: 5px;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  border: none;
}

.dropdown_items_custom {
  height: 35px;
  width: 50%;
  text-align: center;
  background-color: #ffffff;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.hotel_card {
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 684px;
}

.view_availability_button {
  border: 1px solid #a9a6a6;
  border-radius: 5px;
  background: #2c2c76;
  color: white;
  width: 100%;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
}

.view_availability_button:hover {
  background-color: #0c0c79;
}

.view_availability_button:active {
  background-color: #060642;
}

.cardStyle {
  position: relative;
  background: #f9f7f7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  margin: 10px;
  border: 1.5px solid #d9d9d9;
}

.name-text {
  padding-top: 6px;
  margin-right: 10px;
}

.plus-circle-icon {
  color: #2c2c76;
  padding-top: 20px;
  position: absolute;
  left: 1px;
  cursor: pointer;
}

.facility_card {
  margin-left: 12px;
  margin-top: 15px !important;
  width: 84% !important;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.facilities_text {
  font-weight: 600;
  font-size: 28px;
  line-height: 44px;
  margin-bottom: 2%;
  padding-top: 40px;
}

.facilities {
  padding-top: 20px;
  margin-left: 8px;
  margin-bottom: 20px;
}

.remove-icon {
  color: #a9a6a6;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 0px;
  margin-right: 10px !important;
}

.add_button {
  border: 1px solid #2c2c76;
  border-radius: 5px;
  background: #2c2c76 !important;
  color: #ffffff;
  width: 100%;
  margin-left: 1% !important;
  margin-top: 35px;
}

.sadd_button:hover {
  background-color: #0c0c79 !important;
}

.add_button:active {
  background-color: #060642 !important;
}
