$purple-color: #2c2c76;
$light-purple-color: #ececf2;

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/Assets/Images/hotel-owner-landing-page.webp"); /* Replace 'your-image.jpg' with the path to your image */
  background-size: cover;
  background-position: center;
  z-index: -1; /* Send the image to the background */
}

.content-container {
  position: relative;
  z-index: 1; /* Keep the content above the image */
  overflow-y: hidden; /* Allow scrolling for content */
  padding: 5%;
  box-sizing: border-box;
  /* Add any additional styling for your content container */
}

.blue-box {
  color: white;
  background-color: rgba(44, 44, 118, 0.8);
  border-radius: 25px;
  padding: 20px!important;

}

.divider {
  height: 1.5px;
  background-color: #fdfcfc;
  //margin: 0;
  opacity: 1;
  //width: 70%;
}

.big-heading {
  font-size: 50px;
  line-height: 60px;
}

.descripto {
  margin-top: 25px!important;
  line-height: 30px;
  font-size: 18px;
  text-align: justify;
}

.view-btn{
  background-color: #f9b625 !important;
  color: #1c1cae !important;
  border: 0px !important;
  box-shadow: 3px 10px 19px rgb(18 18 18 / 60%);
  height: 48px;
  text-transform: uppercase;
  font-weight: 500 !important;
}

.register-btn {
  height: 48px;
  text-transform: uppercase;
  font-weight: 500 !important;
}




.card-box {
  background-color: rgba(255, 0, 0, 0)!important;
  border: solid 2px yellow!important;
  color: white!important;
}