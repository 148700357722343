.button-cancel {
  border: 2px solid red !important;
  background-color: transparent !important;
  color: red !important;
  transition: background-color 0.3s, color 0.3s !important;
  height: 30px !important;
  width: 40px !important;
  align-items: center !important;
  justify-content: center !important;
  align-content: center !important;
  padding: 2px !important;

  &:hover {
    background-color: red !important;
    color: white !important;
  }
  // &:disabled {
  //   border-color: gray !important;
  //   color: gray !important;
  //   cursor: not-allowed;
  // }
}

.button-refund {
  background-color: #ed9b28;
  outline: none;
  border: 1px solid #f0ad4e;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 12px;
  letter-spacing: 1px;
  transition: all 0.3s;
  border-radius: 5px;

  &:hover {
    background-color: #e8c189;
    border-color: #a26d22;
  }

  &:disabled {
    background-color: rgb(200, 188, 188) !important;
    border-color: gray !important;
    color: gray !important;
    cursor: not-allowed;
  }
}
