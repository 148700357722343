.titles {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 0px!important;
}
.card {
  margin-top: 35px;
  width: 365px;
}

.room_type {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-top: 3px;
}
.room_details {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.searchImage {
  background-repeat: no-repeat;
  background-size: auto;
  padding: 9px;
  border-radius: 20px;
}

.searchDetails {
  padding: 9px;
  height: 100px;
}

.roomDate {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  height: 18px;
  color: #555555;
}
