@import "../../theme.scss";

.main_container_usernft {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex: 1;
}

.nft-items-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}
