@import "theme.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primaryButton {
  background-color: $light-purple-color !important;
  color: $purple-color !important;
  border: none !important;
}

.primaryButton:hover,
.primaryButton:active,
.primaryButton:focus {
  background-color: $purple-color !important;
  color: $light-purple-color !important;
  border: none !important;
  box-shadow: none !important;
}

.secondaryButton {
  background-color: $purple-color !important;
  color: $light-purple-color !important;
  border: none !important;
}

.secondaryButton:hover,
.secondaryButton:active,
.secondaryButton:focus {
  background-color: $light-purple-color !important;
  color: $purple-color !important;
  border: none !important;
  box-shadow: none !important;
}

.smallMargin {
  margin: 10px;
}

.smallMarginLeftRight {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.smallMarginTopBottom {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.noMargin {
  margin: 0 !important;
}

.fontBold {
  font-weight: 800;
}

.spinnerWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noPadding {
  padding: 0 !important;
}

/** scroll to top button styles */
.scroll-to-top {
  z-index: 9999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, background-color 0.3s;
}

.scroll-to-top.visible {
  opacity: 1;
  animation: pulse 1s ease 0s 3 normal none running;
}

.scroll-to-top:hover {
  background-color: #0056b3;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

/** end - scroll to top button styles */
