@import "../../index.scss";

.backgroundShape {
  background: $purple-color;
  width: 90%;
  height: 300px;
  -ms-transform: skewY(-20deg);
  -webkit-transform: skewY(-20deg);
  transform: skewY(-20deg);
  border-radius: 30px;
  position: absolute;
  top: 0px;
  left: -30px;
}
.content {
  position: absolute;
  top: 130px;
  left: 20%;
  right: 20%;
  z-index: 10;
  background-color: rgba(44, 44, 118, 0.8);
  border-radius: 25px;
  padding: 20px;
  color: white;
}

.breaker{
  height: 20px;
}
.heading {
  font-size: 80px;
  line-height: 100px;

}

.description {
  line-height: 30px;
  font-size: 18px;
}

.heroImage {
  background-image: url("/Assets/Images/hotel-owner-landing-page.webp"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 130vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: repeat-y ; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.secretWrapper {
  margin-top: 25px;
  width: 50%;
}

.buttonOverride {
  margin-right: 10px;
}
