.checkinoutTime {
  color: grey;
}

.bookingDetailCard {
  padding: 20px;
  p {
    margin-bottom: 0px;
  }
}
