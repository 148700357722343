.footer {
    padding-top: 2rem;
    background-color: #2C2C76;
    padding-bottom: 1rem;

    .row {
        color: white;

        .image-container {
            width: 150px;
            height: 40px;

            margin-left: -15px;
            .logo {
                width: 84%;
                height: 122%;
                background-size: cover;
            }
        }

        .image-container:hover {
            cursor: pointer;
        }

        .customer_support {
            margin-top: 1.5rem;
        }

        .powered_by {
            margin-top: 15px;
        }
        
        .powered_by_text { 
            padding-bottom: 80px;
        }

        h4 {
            margin-bottom: 0px !important;
        }

    }

}