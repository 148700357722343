$purple-color: #2c2c76;
$light-purple-color: #ececf2;

.confirm_button {
    border: 1px solid #A9A6A6 !important;
    border-radius: 5px;
    background:  rgb(44 44 118);
    background-color:   rgb(44 44 118) !important; 
  }
  
  .confirm_button:hover {
    background-color: #0c0c79; 
  }
  
  .confirm_button:active {
    background-color: #060642;
  }
  
  .confirm_button:disabled {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color:  #060642 !important;
  }