@import "../../index.scss";

.plannerbutton{
    margin-right: 10px;
    background-color: $purple-color!important;
    border-radius: 24px!important;
    width: max-content;
    .svg_img{

    }
}

.buttonNotSelected{
    margin-right: 10px;
    background-color: white!important;
    border-color:  $purple-color!important;
    color:  $purple-color!important;
    border-radius: 24px!important;
    width: max-content;
    .svg_img{
        fill: $purple-color;
        stroke: $purple-color;
    }
    
}

.explorecd{
    img{
        height: 232px;
        width: 254px;
    }
}


