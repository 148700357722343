@import "../../../theme.scss";

.nft-item {
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

  .nft-info {
    p {
      margin: 20px 0;
      font-size: 14px;
      color: #333;
    }
  }

  .details-toggle {
    width: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    color: $purple-color;
    font-weight: bold;

    .toggle-icon {
      transition: transform 0.3s;
      color: #007bff;

      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  .nft-details {
    margin-top: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow: auto;

    &:hover {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      &::-webkit-scrollbar {
        display: block;
      }
    }

    &::-webkit-scrollbar {
      display: none;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e0e0e0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }

    .facilities-p {
      color: #6d6f72;
      font-weight: bold;
      margin: 0;
      padding: 0;
      font-size: 15px;
    }

    .details-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .details-row-p {
        color: #6d6f72;
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
      }

      .details-row-span {
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: #5f6d80;
      }
    }

    .details-row-code {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;

      .details-row-p-code {
        color: #6d6f72;
        font-weight: bold;
        margin: 0;
        padding: 0;
        font-size: 15px;
      }

      .details-row-span-code {
        margin: 0;
        padding: 0;
        color: #5f6d80;
        font-size: 15px;
      }
    }

    .details-row-contact-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-top: 10px;

      p {
        width: 100%;
        margin: 0;
        padding: 0;
        color: #6d6f72;
        font-weight: bold;
        font-size: 15px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
      }

      div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;

        p {
          width: auto;
          margin: 0;
          padding: 0;
          font-weight: normal;
          border: none;
        }

        span {
          margin: 0;
          padding: 0;
          font-weight: normal;
          color: #5f6d80;
        }
      }
    }
  }
}

.nft-link {
  text-decoration: none;
  color: #3498db;
  font-weight: bold;
  border: 2px solid #3498db;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #3498db;
    color: #fff;
  }
}

.hotel-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .image-wrapper {
    width: 100%;

    .main-image {
      width: 100%;
      height: 120px;
      object-fit: cover;
      border-radius: 5px;
      box-shadow: 0 2px 4px $yellow-color;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: scale(1.01);
        box-shadow: 0 2px 5px $yellow-color;
      }
    }
  }

  .image-grid {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .grid-image {
      width: 49%;
      height: 120px;
      object-fit: cover;
      border-radius: 5px;
      box-shadow: 0 2px 4px $yellow-color;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: scale(1.01);
        box-shadow: 0 2px 5px $yellow-color;
      }
    }
  }
}
