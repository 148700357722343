.sub-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
  }

  .check-in{
    padding-right: 200;
  }
  .check-out{
    padding-left: 200;
  }