@import "../../../theme.scss";

.sell-offer-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.search-bar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 80%;
  padding: 20px;
  margin-top: 20px;
  background-color: #f0f2f5;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease;

  // in smaller screens, set max-width to 100%
  @media (max-width: 600px) {
    max-width: 100%;
  }

  &:hover {
    background-color: lighten(#f0f2f5, 3%);
  }

  .first-row,
  .second-row,
  .third-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
  }

  .first-row {
    .search-field {
      flex: 1;
      min-width: 220px;
      display: flex;
      flex-direction: column;

      label {
        font-size: 14px;
        font-weight: 500;
        color: $purple-color;
        margin-bottom: 5px;
      }

      input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        color: #333;
        transition: border-color 0.3s;

        &:focus {
          border-color: $purple-color;
          outline: none;
          box-shadow: 0 0 4px $purple-color;
        }
      }
    }
  }

  .second-row {
    display: flex;
    flex-direction: column;
    .search-field {
      flex: 1;
      min-width: 220px;
      display: flex;
      flex-flow: row wrap;
      gap: 5px;

      .input-container {
        flex: 1;
        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;
        }
      }

      // change flex direction to column in smaller screens
      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    .buttons-container {
      display: flex;
      gap: 5px;
      .button-marketplace {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border: none;
        outline: none;
        border-radius: 5px;
        background-color: $purple-color;
        color: #fff;
        gap: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

        &:hover {
          background-color: darken($purple-color, 10%);
          color: $yellow-color;
        }

        .button-icon-marketplace {
          font-size: 16px;
        }

        .button-text-marketplace {
          font-size: 16px;
        }
      }
    }

    .facilities-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 10px;
    }

    .facility-item {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      background-color: $purple-color;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      position: relative;

      .remove-facility-icon {
        margin-left: 5px;
        cursor: pointer;
        color: crimson;
      }
    }
  }
}

.sell-offers-list-marketplace {
  width: 100%;
  display: grid;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 30px;

  grid-template-columns: 1fr;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 150px;
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 10px 150px;
  }
}

.no-offers-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  gap: 20px;
  color: $purple-color;
  background-color: #fff;
}

.no-offers-sub-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 $yellow-color;
}

.no-offers-title {
  font-size: 18px;
  font-weight: bold;
}

.no-offers-icon {
  color: $yellow-color;
}
