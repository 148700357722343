.button-success-outline {
    border: 2px solid #008000!important;
    background-color: transparent !important;
    color: #008000 !important;
    transition: background-color 0.3s, color 0.3s !important;
    height: 30px !important;
    width: 40px !important;
    margin-top: 6px !important;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    &:hover {
      background-color:#008000 !important;
      color: white !important;
    }
  
    // &:disabled {
    //   border-color: lightgray !important;
    //   color: lightgray !important;
    //   cursor: pointer;
    // }
  }
  
  .button-cancel-outline {
    border: 2px solid red !important;
    background-color: transparent !important;
    color: red !important;
    transition: background-color 0.3s, color 0.3s !important;
    height: 30px !important;
    width: 40px !important;
    margin-top: 6px !important;
    margin-left: 1px !important;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
  
    &:hover {
      background-color: red !important;
      color: white !important;
    }
  
    // &:disabled {
    //   border-color: lightgray;
    //   color: lightgray;
    //   cursor: not-allowed;
    // }
  }
  .complete_btn {
    border: 2px solid #008000 !important;
    background-color: transparent !important;
    color: #008000 !important;
    transition: background-color 0.3s, color 0.3s !important;
    padding: 5px !important;
    width: 105px !important;
    margin: 10px !important; 
    text-align: center !important; 
    display: flex !important; 
    align-items: center !important; 
    justify-content: center !important; 
    font-size: 15px !important; 
    font-weight: 600 !important;
    
    &:hover {
      background-color: #008000 !important;
      color: white !important;
    }
  }
  
  