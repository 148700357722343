$main-yellow-color: rgb(251 183 37);
$main-blue-color: rgb(44 44 118);

.page-header {
  color: black;
  font-size: 2rem;
  font-weight: 500;
}

.account-address {
  font-size: 1rem;
  color: rgb(0, 0, 0);
  color: $main-blue-color;
  margin-top: -7px;
}

.cell-texts {
  margin: 5px 0;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.balance-card {
  background-color: #e0f7fa; /* Very light blue color */
  border: 1px solid #64b5f6; /* Light blue border */
  color: $main-blue-color !important;
  box-shadow: 3px 10px 19px rgb(18 18 18 / 60%);
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition for hover effect */

  &:hover {
    background-color: #e5eff0; /* Dodgerblue-like color on hover */
    border-color: #1c7ed6; /* Darker blue border on hover */
  }
}

.card-label {
  font-size: 30px;
  font-weight: 500;
}

.ever-balance-value {
  font-weight: 350 !important;
  line-height: 74px;
  display: block;
}

.font-size-10 {
  font-size: 10px;
}

.evr-label {
}

.activation-message {
  padding: 10px;
  background-color: rgb(204, 226, 233);
  color: midnightblue;
  border-radius: 5px;
  text-align: center;
}

@media only screen and (min-width: 992px) {
  .ever-balance-value {
    font-size: 58px;
    line-height: 74px;
  }
}

@media only screen and (max-width: 992px) {
  .ever-balance-value {
    font-size: 45px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 768px) {
  .ever-balance-value {
    font-size: 115px;
    line-height: 125px;
  }
}

@media only screen and (max-width: 600px) {
  .ever-balance-value {
    font-size: 110px;
    line-height: 120px;
  }
}

@media only screen and (max-width: 386px) {
  .ever-balance-value {
    font-size: 32px;
    line-height: 42px;
  }
}
.center-text {
    text-align: center;
    vertical-align: middle;
  }
  
